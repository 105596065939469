export default {
    debug: false,

    basePath: "",
    defaultSceneID: "terrain-gen",
    scene404ID: "404",

    mobileBreakpoint: 768,

    // Fading
    fadeColors: ["#0066FF", "#00CC66", "#FF9900", "#FF3333", "#FF3399", "#993399", "#0066FF"],
    totalTime: 6
}