import config from '../config';
import SBScene from './scene.js';
import * as THREE from 'three';
import { getState } from '../script.js';
import { SimplexNoise } from 'simplex-noise';
import { Helpers, ListenerRecord } from '../handlers/helpers.js';
import { AccelerationControls } from '../handlers/accelerationControls';
import { InputType } from '../enums';

export default class TerrainGen extends SBScene {

    constructor() {
        super();
    }

    static getDefaults() {
        return {
            id: "terrain-gen",

            plane: null, // terrain ground plane
            timeSpeed: 0, // 
            offsetTime: 0, // time to offset by
            timeScalar: 0.4, // how fast time should move

            geometry: null, // terrain geo
            simplex: new SimplexNoise(), // simplex noise function to gen terrain
            xZoom: 6,
            yZoom: 18,
            noiseStrength: 1.2,

            spaceship: null, // spaceship object 

            // Camera controls
            camZOffset: 2,
            camZOffsetIntensity: 1,

            // Controls
            accelControlsX: null,
            accelControlsSpeed: null,
            maxShipRotation: Math.PI * 0.25,
            leftShipXBound: -Infinity,
            rightShipXBound: Infinity,

            pointer: new THREE.Vector3(0, 0, 0),
            pointerWorldPos: new THREE.Vector3(0, 0, 0),
            windowEventListeners: [],
            deadzone: 1,
        }

    }

    onCreate() {
        const state = getState();

        this.setupPlane();
        this.setupLights();

        // Start with plane color
        this.plane.material.color = Helpers.getFadedColor(0);


        // Set camera position and rotation
        const camera = state.camera;
        camera.position.x = 0;
        camera.position.y = -20;

        camera.rotation.x = Math.PI * 0.35;
        camera.rotation.y = 0;
        camera.rotation.z = 0;

        camera.position.y = -22;

        state.controls.enabled = false;

        // Setup scene enviroment
        state.scene.background = new THREE.Color(255, 255, 255);
        state.scene.fog = new THREE.Fog(new THREE.Color(255, 255, 255), 0, 1000);


        // Load the spaceship
        Helpers.loadModel("/spaceship.gltf").then((gltf) => {
            this.spaceship = gltf.scene.children[0]; // get the spaceship model

            // Create / set the ship
            this.spaceship.material = new THREE.MeshStandardMaterial({
                roughness: 0.8,
                emissive: new THREE.Color(0x333333),
                emissiveIntensity: 1,
                color: new THREE.Color(0x333333)
            });

            // Position ship
            this.spaceship.scale.set(0.15, 0.15, 0.15);
            this.spaceship.position.set(0, -15, 2);
            this.spaceship.rotation.set(-1 * Math.PI, 0, 0.5 * Math.PI);

            this.scene.add(this.spaceship);

        });

        // Setup controls for x movement
        this.accelControlsX = new AccelerationControls({
            touchEnabled: window.innerWidth < config.mobileBreakpoint
        });

        this.accelControlsX.addEventListener((controls) => {
            // Set spaceship position
            if (!this.spaceship) {
                return;
            }

            this.spaceship.position.x += controls.speed;

            // // Calculate rotation based on speed
            const rotationPercentage = -controls.speed / controls.maxSpeed;

            this.spaceship.rotation.y = rotationPercentage * this.maxShipRotation;
        });

        // Setup controls for speed
        this.accelControlsSpeed = new AccelerationControls({
            touchEnabled: false,
            doubleTapEnabled: true,
            decrementingEnabled: false,
            incrementKeys: ["w", "ArrowUp"],
            maxSpeed: 0.02,
            accelPerTick: 0.0001,
            decrementKeys: ["s", "ArrowDown"],
        });

        this.accelControlsSpeed.addEventListener((controls) => {
            const percentage = Math.max(0, controls.speed / controls.maxSpeed);

            this.camZOffsetIntensity = 1.1 - percentage;
            this.camZOffset = 2 + percentage * 5;
            this.timeSpeed = Math.max(0, controls.speed);
        });

        // Listen to move move events and handle pointer position accordingly
        this.linkEventListener(new ListenerRecord(window, "mousemove", (event) => {
            this.pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
            this.pointer.y = - (event.clientY / window.innerHeight) * 2 + 1;
        }));
    }


    updatePointerWorldPos() {
        const camera = getState().camera;

        // Calculate ray direction from mouse
        const mouseVec = this.pointer.clone();
        mouseVec.unproject(camera);
        mouseVec.normalize();
        mouseVec.y = -mouseVec.y;

        const planeNormal = new THREE.Vector3(0, -1, 0);
        const planePoint = new THREE.Vector3(0, -15, 0);

        const result = Helpers.calcRayPlaneIntersect(mouseVec, camera.position.clone(), planeNormal, planePoint);
        this.pointerWorldPos = result; // update the pointers position in the world

        if (this.pointerWorldPos.x > this.deadzone + this.spaceship.position.x) {
            this.accelControlsX.holdIncrementKey(InputType.POINTER);
        } else if (this.pointerWorldPos.x < this.spaceship.position.x - this.deadzone) {
            this.accelControlsX.holdDecrementKey(InputType.POINTER);
        } else {
            this.accelControlsX.releaseDecrementKey(InputType.POINTER);
            this.accelControlsX.releaseIncrementKey(InputType.POINTER);
        }

    }


    onDestroy() {
        this.accelControlsX.destroy();
        this.accelControlsSpeed.destroy();
        // Remove registered listeners
        for (const listener of this.windowEventListeners) {
            window.removeEventListener(listener);
        }
    }

    onTick(elapsedTime) {

        this.offsetTime += this.timeSpeed
        const offset = elapsedTime * this.timeScalar + this.offsetTime;

        this.plane.material.color = Helpers.getFadedColor(offset);
        this.adjustVertices(offset);
        this.adjustCameraPos(offset);

        if (this.spaceship) {
            this.updateBounds();

            this.spaceship.material.emissive = Helpers.getFadedColor(offset);
            this.accelControlsX.onTick();
            this.accelControlsSpeed.onTick();
            this.updatePointerWorldPos();

            // Reset to other side if out of bounds
            if (this.spaceship.position.x < this.leftShipXBound) {
                this.spaceship.position.x = this.leftShipXBound;
            } else if (this.spaceship.position.x > this.rightShipXBound) {
                this.spaceship.position.x = this.rightShipXBound;
            }
        }
    }


    /**
     * Calculate the most negative and postive x value to determine
     * the bounds of the ship where it should loop
     */
    updateBounds() {
        const camera = getState().camera;
        const planeNormal = new THREE.Vector3(0, -1, 0);
        const planePoint = new THREE.Vector3(0, -15, 0);

        // calculate left bound
        const leftBoundVec = new THREE.Vector3(-1, 0, 0);
        leftBoundVec.unproject(camera);
        leftBoundVec.y = -leftBoundVec.y;
        leftBoundVec.normalize();

        // calculate left bound
        const rightBoundVec = new THREE.Vector3(1, 0, 0);
        rightBoundVec.unproject(camera);
        rightBoundVec.y = -rightBoundVec.y;
        rightBoundVec.normalize();


        const leftBound = Helpers.calcRayPlaneIntersect(leftBoundVec, camera.position.clone(), planeNormal, planePoint);
        const rightBound = Helpers.calcRayPlaneIntersect(rightBoundVec, camera.position.clone(), planeNormal, planePoint);

        const boundingBox = this.spaceship.geometry.boundingBox;
        const shipWidth = boundingBox.max.x - boundingBox.min.x;
        this.leftShipXBound = leftBound.x - shipWidth / 3;
        this.rightShipXBound = rightBound.x + shipWidth / 3;
    }

    /**
     * Create the terrain plane
     */
    setupPlane() {

        let side = 140;
        this.geometry = new THREE.PlaneGeometry(100, 100, side, side);
        let material = new THREE.MeshStandardMaterial({
            roughness: 0.8,
            color: new THREE.Color(0xFFFFFF),
        });
        this.plane = new THREE.Mesh(this.geometry, material);
        this.plane.castShadow = true;
        this.plane.receiveShadow = true;
        this.scene.add(this.plane);
    }

    setupLights() {
        let ambientLight = new THREE.AmbientLight(0x0c0c0c);
        this.scene.add(ambientLight);

        let spotLight = new THREE.SpotLight(0xcccccc);
        spotLight.position.set(-30, 60, 60);
        spotLight.castShadow = true;
        this.scene.add(spotLight);
    }


    adjustVertices(offset) {
        const vertices = this.plane.geometry.getAttribute("position").array;
        for (let i = 0; i < vertices.length; i += 3) {
            let x = vertices[i] / this.xZoom;
            let y = vertices[i + 1] / this.yZoom;
            let noise = this.simplex.noise2D(x, y + offset) * this.noiseStrength;
            vertices[i + 2] = noise;
        }
        this.plane.geometry.dispose();
        this.plane.geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
        this.plane.geometry.attributes.position.needsUpdate = true;
        this.plane.geometry.computeVertexNormals();


    }

    adjustCameraPos(offset) {
        const camera = getState().camera;
        let x = camera.position.x / this.xZoom;
        let y = camera.position.y / this.yZoom;
        let noise = this.simplex.noise2D(x, y + offset) * this.noiseStrength + 1.5;
        camera.position.z = this.camZOffsetIntensity * noise + this.camZOffset;
    }


}