import './style.scss'
import './styles/fonts.scss';

import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import { gsap, random, } from 'gsap/all';
import { SceneManager } from './handlers/sceneManager';
import config from './config';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js';
import { HistoryHandler } from './handlers/historyHandler';
import { MenuHandler } from './handlers/menuHandler';

const state = {
    clock: new THREE.Clock(),
    renderer: null,
    camera: null,
    gui: new dat.GUI(),
    scene: new THREE.Scene(),
    canvas: document.querySelector('canvas.webgl'),
    sizes: {
        width: window.innerWidth,
        height: window.innerHeight
    },
    controls: null,

    postprocessing: {
        composer: null,
        bokeh: null
    },

    // Loaders
    dracoLoader: new DRACOLoader(),
    gltfLoader: new GLTFLoader()
}

export function getState() {
    return state;
}


const sceneManager = SceneManager.getInstance();
const historyHandler = HistoryHandler.getInstance();
const menuHandler = MenuHandler.getInstance();

init();
function init() {
    if (!config.debug) {
        document.querySelector(".dg.ac").style.display = "none";
    }

    // set loaders
    state.dracoLoader.setDecoderPath('/draco/');
    state.gltfLoader.setDRACOLoader(state.dracoLoader);


    state.camera = new THREE.PerspectiveCamera(75, state.sizes.width / state.sizes.height, 0.1, 1000);

    window.addEventListener('resize', () => {
        // Update sizes
        state.sizes.width = window.innerWidth
        state.sizes.height = window.innerHeight

        // Update camera
        state.camera.aspect = state.sizes.width / state.sizes.height
        state.camera.updateProjectionMatrix()

        // Update renderer
        state.renderer.setSize(state.sizes.width, state.sizes.height)
        state.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })

    /**
     * Camera
     */
    // Base camera
    state.camera.position.x = 3
    state.camera.position.y = 3
    state.camera.position.z = 3

    // Controls
    state.controls = new OrbitControls(state.camera, state.canvas);
    state.controls.enableDamping = true;
    /**
     * Renderer
     */
    state.renderer = new THREE.WebGLRenderer({
        canvas: state.canvas
    })
    state.renderer.setSize(state.sizes.width, state.sizes.height)
    state.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    initPostProcessing();
    historyHandler.init();

    /**
     * Animate
     */

    const tick = () => {
        const elapsedTime = state.clock.getElapsedTime()

        // Update controls
        if (state.controls.enabled) {
            state.controls.update()
        }


        sceneManager.tick(elapsedTime);

        // Render
        state.postprocessing.composer.render(0.1);

        // Call tick again on the next frame
        window.requestAnimationFrame(tick)
    }

    tick()

    var toggleScene = true;
    // document.addEventListener("keypress", function () {
    //     toggleScene = !toggleScene;
    //     sceneManager.switchScene(toggleScene ? "terrain-gen" : "galaxy-overview");
    // });
}


function initPostProcessing() {
    const renderPass = new RenderPass(state.scene, state.camera);


    // Setup the bokeh
    const bokehPass = new BokehPass(state.scene, state.camera, {
        focus: 6,
        aperture: 0.00025,
        maxblur: 0.01,

        width: state.sizes.width,
        height: state.sizes.height
    });

    const composer = new EffectComposer(state.renderer);

    composer.addPass(renderPass);
    composer.addPass(bokehPass);

    state.postprocessing.composer = composer;
    state.postprocessing.bokeh = bokehPass;

}
