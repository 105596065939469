
import SBScene from './scene.js';
import * as THREE from 'three';
import { TweenLite } from 'gsap/gsap-core';
import fragment from '../shaders/distortion/fragment.glsl';
import vertex from '../shaders/distortion/vertex.glsl';
import { getState } from '../script.js';
import config from '../config';

export default class About extends SBScene {

    constructor() {
        super();
    }

    static getDefaults() {
        return {
            id: "about",
            mat: null,
            sliderImages: []
        }

    }

    template() {
        return `
        <div id="pagination">
				<button class="active" data-slide="0"></button>
				<button data-slide="1"></button>
				<button data-slide="2"></button>
		</div>`
    }

    onCreate() {
        this.createDisplacementSlider({
            images: [
                "/textures/portraits/marcel_django.jpg",
                "/textures/portraits/thijs.jpg",
                "/textures/portraits/patrizia.jpg "
            ]
        });

        const random = 1 + Math.round(Math.random());
        // Set default skybox
        getState().scene.background = new THREE.CubeTextureLoader()
            .setPath(`${config.basePath}/textures/cubemaps/skybox${random}/`)
            .load([
                'right.png',
                'left.png',
                'top.png',
                'bottom.png',
                'front.png',
                'back.png'
            ]);



        // Set camera position
        getState().camera.position.set(0, 0, 15);
        getState().camera.rotation.set(0, 0, 0);

    }

    createDisplacementSlider(opts) {
        const state = getState();
        const renderer = state.renderer;
        const scene = this.scene;

        let images = opts.images, image = [];

        let loader = new THREE.TextureLoader();
        loader.crossOrigin = "anonymous";

        images.forEach((img) => {
            image = loader.load(img);
            image.magFilter = image.minFilter = THREE.LinearFilter;
            image.anisotropy = renderer.capabilities.getMaxAnisotropy();
            this.sliderImages.push(image);
        });

        scene.background = new THREE.Color(0x23272A);


        this.mat = new THREE.ShaderMaterial({
            uniforms: {
                dispFactor: { type: "f", value: 0.0 },
                currentImage: { type: "t", value: this.sliderImages[0] },
                nextImage: { type: "t", value: this.sliderImages[1] },
            },
            vertexShader: vertex,
            fragmentShader: fragment,
            transparent: true,
            opacity: 1.0
        });

        this.mat = this.mat;

        this.geometry = new THREE.PlaneBufferGeometry(
            20,
            20,
            1,
            1
        );
        let object = new THREE.Mesh(this.geometry, this.mat);
        object.position.set(0, 0, 0);
        scene.add(object);

        this.linkEvents();
    }

    linkEvents() {

        let pagButtons = Array.from(document.getElementById('pagination').querySelectorAll('button'));
        let isAnimating = false;

        pagButtons.forEach((el) => {

            el.addEventListener('click', function (evt) {

                if (!isAnimating) {

                    isAnimating = true;

                    document.getElementById('pagination').querySelectorAll('.active')[0].className = '';
                    evt.target.className = 'active';

                    let slideId = parseInt(evt.target.dataset.slide, 10);

                    this.mat.uniforms.nextImage.value = this.sliderImages[slideId];
                    this.mat.uniforms.nextImage.needsUpdate = true;

                    TweenLite.to(this.mat.uniforms.dispFactor, 1, {
                        value: 1,
                        ease: 'Expo.easeInOut',
                        onComplete: function () {
                            this.mat.uniforms.currentImage.value = this.sliderImages[slideId];
                            this.mat.uniforms.currentImage.needsUpdate = true;
                            this.mat.uniforms.dispFactor.value = 0.0;
                            isAnimating = false;
                        }.bind(this)
                    });


                }

            }.bind(this));

        });




    };
}




