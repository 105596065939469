import SBScene from './scene.js';
import * as THREE from 'three';

export default class SimpleScene extends SBScene {

    constructor() {
        super();
    }

    static getDefaults() {
        return {
            id: "simple-scene"
        }

    }

    onCreate() {
        const geometry = new THREE.BoxGeometry();
        const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        const cube = new THREE.Mesh(geometry, material);
        this.scene.add(cube);

    }


}