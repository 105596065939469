import config from '../config';
import logo from '../assets/logo.svg';
import burgerIcon from '../assets/burger-icon.svg';
import { gsap } from 'gsap';


export class MenuHandler {
    _instance;
    containerElement; // the containing dom element of the overlays
    burgerNavEl; // the dom element of the burger menu nav
    logoNavEl; // the dom element of the logo 
    menuOverlayContainerEl; // the overlay containe dom element

    menuAnimDuration = .3; // total duration of menu entrance / leaving anim
    menuTimeline; // the gsap timeline instance of the menu animation

    constructor() {

        // Add template to dom
        this.containerElement = document.createElement("div");
        this.containerElement.id = "sb-overlays-container";
        this.containerElement.innerHTML = this.template();
        document.body.prepend(this.containerElement);

        this.burgerNavEl = this.containerElement.querySelector("#sb-burger-nav");
        this.logoNavEl = this.containerElement.querySelector("#sb-logo-nav");
        this.menuOverlayContainerEl = this.containerElement.querySelector("#sb-menu-overlay");

        // Link events
        this.burgerNavEl.addEventListener("click", this.toggleMenu.bind(this))
        this.logoNavEl.addEventListener("click", this.toggleMenu.bind(this));

        this.initMenuAnimation();

    }

    /**
     * HTML Template string for overlays
     * @returns the template string
     */
    template() {
        return /*html*/`<!-- Logo icon-->
<a id="sb-logo-nav"">
    <div class="sb-logo-icon sb-button">
        ${logo}
    </div>
</a>

<!-- burger icon-->
<a id="sb-burger-nav">
    <div class="sb-burger-icon sb-button">
        ${burgerIcon}
    </div>
</a>

<!--Main menu navigation overlay-->
<div id="sb-menu-overlay">
    <div class="sb-row">
        <div id="nav-col" class="sb-col">
        <a class="sb-menu-item" href="#terrain-gen">
                <h1> Terrain-gen </h1>
            </a>
        </div>
        <div class="sb-col">
            <a class="sb-menu-item" href="#galaxy-overview">
                <h1> Galaxy Overview </h1>
            </a>
        </div>
        <div class="sb-col">
        <a class="sb-menu-item" target="_blank" href="https://spaceblend-site.pages.dev/">
            <h1>> Space Station</h1>
        </a>
        </div>
        <div class="sb-col">
        <a class="sb-menu-item" target="_blank" href="https://qc3.pages.dev/">
            <h1>> 3D Globe </h1>
        </a>
        </div>
        <div class="sb-col">
        <a class="sb-menu-item" target="_blank" href="https://skyxtraders-hero.pages.dev/">
            <h1>> Skyxtraders</h1>
        </a>
        </div>
        <div class="sb-col">
        <a class="sb-menu-item" target="_blank" href="https://spaceblend.com/werbelechner/">
            <h1>More...</h1>
        </a>
        </div>
    </div>

</div>`
    }

    /**
     * Singleton pattern for History Handler
     * @returns instance of the history handler
     */
    static getInstance() {
        if (this._instance == null) {
            this._instance = new MenuHandler();
        }

        return this._instance;
    }


    /**
     * Toggle the menu
     * @param {*} forceClose optionally force close
     */
    toggleMenu(forceClose) {

        if (this.menuOverlayContainerEl.classList.contains("sb-active")) {
            this.closeMenu();
        } else {
            this.openMenu();
        };
    }

    /**
     * Close the menu 
     */
    closeMenu() {
        this.menuTimeline.reverse();
        this.menuOverlayContainerEl.classList.remove("sb-active");
    }

    /**
     * Open the main menu
     */
    openMenu() {
        this.menuTimeline.play();
        this.menuOverlayContainerEl.classList.add("sb-active");

    }


    /**
     * Initialize the main menu animation
     */
    initMenuAnimation() {
        this.menuTimeline = gsap.timeline({ paused: true });
        this.menuTimeline.fromTo("#sb-menu-overlay > .sb-row > .sb-col", { y: "-50%", opacity: 0 }, { y: "0%", opacity: 1, duration: this.menuAnimDuration, stagger: this.menuAnimDuration / 6 });
        this.menuTimeline.fromTo("#sb-menu-overlay", { display: "none" }, { display: "block", duration: .6 }, "<");

    }
}
