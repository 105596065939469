
import { getState } from '../script.js';
import SBScene from './scene.js';
import * as THREE from 'three';
import galaxyVertexShader from '../shaders/galaxy/vertex.glsl'
import galaxyFragmentShader from '../shaders/galaxy/fragment.glsl'

export default class GalaxyOverview extends SBScene {

    constructor() {
        super();
    }

    static getDefaults() {
        return {
            id: "galaxy-overview",

            parameters: {
                durationPerColor: 5,
                timeOffset: 9,
                timeSpeedScalar: 0.3,
                count: 200000,
                size: 0.005,
                radius: 5,
                branches: 3,
                spin: 1,
                randomness: 0.2,
                randomnessPower: 3,
                insideColor: "#ff6030",
                outsideColor: "#1b3984"
            },
            
            guiItems: [],
            geometry: null,
            material: null,
            points: null,

            guiFolder: null
        }
    }


    onDestroy() {
        getState().gui.removeFolder(this.guiFolder);
    }


    onCreate() {
        getState().scene.background= null;

        this.guiFolder = getState().gui.addFolder("galaxy-settings")

        // ADD Debug controls
        this.guiFolder.add(this.parameters, 'timeOffset').min(0).max(100).step(1).onFinishChange(this.generateGalaxy.bind(this))
        this.guiFolder.add(this.parameters, 'timeSpeedScalar').min(0).max(20).onFinishChange(this.generateGalaxy.bind(this));
        this.guiFolder.add(this.parameters, 'count').min(100).max(1000000).step(100).onFinishChange(this.generateGalaxy.bind(this))
        this.guiFolder.add(this.parameters, 'radius').min(0.01).max(20).step(0.01).onFinishChange(this.generateGalaxy.bind(this))
        this.guiFolder.add(this.parameters, 'branches').min(2).max(20).step(1).onFinishChange(this.generateGalaxy.bind(this))
        this.guiFolder.add(this.parameters, 'randomness').min(0).max(2).step(0.001).onFinishChange(this.generateGalaxy.bind(this))
        this.guiFolder.add(this.parameters, 'randomnessPower').min(1).max(10).step(0.001).onFinishChange(this.generateGalaxy.bind(this))

        // generate the galaxy
        this.generateGalaxy();

        // reset camera position
        const camera = getState().camera;
        camera.position.set (0,3,3);
    }


    onTick(elapsedTime) {
        // Update material
        if (this.material) {
            this.material.uniforms.uTime.value = this.parameters.timeOffset + elapsedTime * this.parameters.timeSpeedScalar;
        }
    }


    generateGalaxy() {
        const state = getState();
        if (this.points !== null) {
            this.geometry.dispose()
            this.material.dispose()
            this.scene.remove(this.points);
            this.scene.remove();
        }

        /**
         * Geometry
         */
        this.geometry = new THREE.BufferGeometry()

        const positions = new Float32Array(this.parameters.count * 3)
        const randomness = new Float32Array(this.parameters.count * 3)
        const colors = new Float32Array(this.parameters.count * 3)
        const scales = new Float32Array(this.parameters.count * 1);
        const radiusPercentages = new Float32Array(this.parameters.count * 1);

        const insideColor = new THREE.Color(this.parameters.insideColor)
        const outsideColor = new THREE.Color(this.parameters.outsideColor)

        for (let i = 0; i < this.parameters.count; i++) {
            const i3 = i * 3

            // Position
            const radius = Math.random() * this.parameters.radius

            const branchAngle = (i % this.parameters.branches) / this.parameters.branches * Math.PI * 2

            const randomX = Math.pow(Math.random(), this.parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * this.parameters.randomness * radius
            const randomY = Math.pow(Math.random(), this.parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * this.parameters.randomness * radius
            const randomZ = Math.pow(Math.random(), this.parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * this.parameters.randomness * radius

            positions[i3] = Math.cos(branchAngle) * radius
            positions[i3 + 1] = 0
            positions[i3 + 2] = Math.sin(branchAngle) * radius

            randomness[i3] = randomX
            randomness[i3 + 1] = randomY
            randomness[i3 + 2] = randomZ

            // Color
            const mixedColor = insideColor.clone()
            const radiusPercentage = radius / this.parameters.radius;

            mixedColor.lerp(outsideColor, radius / this.parameters.radius)

            colors[i3] = mixedColor.r
            colors[i3 + 1] = mixedColor.g
            colors[i3 + 2] = mixedColor.b

            // radius percentage
            radiusPercentages[i] = radiusPercentage;

            // Scale
            scales[i] = Math.random()
        }
        this.geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
        this.geometry.setAttribute('aRandomness', new THREE.BufferAttribute(randomness, 3))
        this.geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
        this.geometry.setAttribute("radiusPercentage", new THREE.BufferAttribute(radiusPercentages, 1))
        this.geometry.setAttribute('aScale', new THREE.BufferAttribute(scales, 1))

        /**
         * Material
         */
        this.material = new THREE.ShaderMaterial({
            depthWrite: false,
            blending: THREE.AdditiveBlending,
            vertexColors: true,
            uniforms:
            {
                uTime: { value: 0 },
                uSize: { value: 30 * state.renderer.getPixelRatio() }
            },
            vertexShader: galaxyVertexShader,
            fragmentShader: galaxyFragmentShader
        })

        /**
         * Points
         */
        this.points = new THREE.Points(this.geometry, this.material)
        this.scene.add(this.points)
    }


}