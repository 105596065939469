import * as THREE from 'three';
import { Helpers } from '../handlers/helpers';
import { getState } from '../script';
import * as dat from 'dat.gui'

export default class SBScene {
    containerEl;
    scene;
    isReady = false;
    linkedListeners = [];

    constructor() {
        this.isReady = false;

        // Set default properties
        const defaults = this.constructor.getDefaults();

        for (const key in defaults) {
            this[key] = defaults[key];
        }

        this.scene = new THREE.Scene(); // create new scene
        this.loadContent();
        this.onCreate();

        this.isReady = true;
    }

    // Loads the default class properties
    static getDefaults() { }

    tick(elapsedTime) {
        // If the initialization is complete start calling the onTick event
        if (this.isReady) {
            this.onTick(elapsedTime);
        }
    }

    onDestroy() { }
    onCreate() { }
    onTick() { }

    linkEventListener(listener) {
        this.linkedListeners.push(listener);
    }

    destroy() {
        for (const listener of this.linkedListeners) {
            try {
                console.log(listener);
                listener.destroy();
            }  catch(e) {}; 
        }

        // Remove dom elements
        this.containerEl.remove();

        // Delete THREE elements
        Helpers.clearThree(this.scene);

        // reset scene
        const state = getState();
        state.scene.background = null;
        state.scene.fog = null;
        state.controls.enabled = true;

        // call function for additional cleanup
        this.onDestroy();
    }

    loadContent() {
        this.containerEl = document.createElement("div");
        this.containerEl.classList.add("sb-scene-container");
        this.containerEl.id = this.id;
        this.containerEl.innerHTML = this.template();

        document.body.appendChild(this.containerEl);
    }

    getID() { return "" }

    template() { return "" }

    /**
     * Getters and setters
     */

    get scene() {
        return this.scene;
    }

}