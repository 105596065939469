import { Helpers, ListenerRecord } from "./helpers";
import { InputType } from '../enums';

export class AccelerationControls {

    incrementingEnabled = true; // if incrementing controls are enabled
    decrementingEnabled = true; // If decremeting controls are enabled

    enabledInputTypes = [InputType.KEYBOARD, InputType.POINTER];

    incrementing = false; // whether we are incrementing the speed

    hasDecKeyDown = false; // if any of the decremting keys is pressed
    hasIncKeyDown = false; // if the any of the incrementing keys is pressed

    accelPerTick = 0.001; // The increase in acceleration per tick
    speed = 0; // the current speed
    maxSpeed = 0.1; // the maximum speed
    autoReset = true; // whether to auto reset speed to center

    incrementKeys = ["ArrowRight", "d"]; // keys of incrementing
    decrementKeys = ["ArrowLeft", "a"]; // keys for decremeting

    listeners = []; // array of listener records to destory / unregister them later

    callbacks = []; // the callbacks on update

    keyInputTimeout = 3000; // time to wait until pointer controls are re-enabled after keyboard input

    constructor(properties) {
        // Copy properties to inner settings
        for (const key in properties) {
            this[key] = properties[key];
        };

        // Link keyboard controls for movement
        this.listeners.push(new ListenerRecord(document, "keydown", (e) => {
            // Decrement logic on key press
            if (this.decrementKeys.includes(e.key)) {
                this.forceKeyboardControl();
                this.holdDecrementKey(InputType.KEYBOARD);
            // Increment logic for key presses
            } else if (this.incrementKeys.includes(e.key) && this.incrementingEnabled) {
                this.forceKeyboardControl();
                this.holdIncrementKey(InputType.KEYBOARD);
            }
        }));

        // Link keyboard controls for movement
        this.listeners.push(new ListenerRecord(document, "keyup", (e) => {
            // Decrement logic for key up
            if (this.decrementKeys.includes(e.key)) {
                this.releaseDecrementKey(InputType.KEYBOARD);
                // Increment logic
            } else if (this.incrementKeys.includes(e.key)) {
                this.releaseIncrementKey(InputType.KEYBOARD);
            }
        }));


    }

    /**
     * Force the control to be only keyboard but reset
     * after keyinputtimeout is over
     */
    forceKeyboardControl() {
        // Override inpout with only keyboard and save previous state
        this.enabledInputTypes = [InputType.KEYBOARD];

        // Debounce to only reenable after key input timeout has passed
        Helpers.debounce(function () {
            this.enabledInputTypes = [InputType.KEYBOARD, InputType.POINTER];
        }.bind(this), this.keyInputTimeout, false)();
    }

    /**
     * Add an event listener 
     * @param {*} cb the callback to call on update
     */
    addEventListener(cb) {
        this.callbacks.push(cb);
    }

    /**
     * Destroy the accelerationcontrols
     */
    destroy() {
        for (const listener of this.listeners) {
            listener.destroy();
        }
    }

    /**
     * Calculate the new speed using the acceleration and controls
     * on every update
     */
    onTick() {
        const hasKeyDown = this.hasDecKeyDown || this.hasIncKeyDown;
        // Accelerate or decelerate

        // If pressing increment
        if (hasKeyDown && this.incrementing) {
            this.speed += this.accelPerTick;
            // If pressing decrement
        } else if (hasKeyDown && !this.incrementing) {
            this.speed -= this.accelPerTick;
            // If not pressing and almost at 0
        } else if (Math.abs(this.speed) <= this.accelPerTick) {
            this.speed = 0;
            // If not pressing decellerate to 0
        } else if (this.speed > 0 && this.autoReset) {
            this.speed -= this.accelPerTick;
        } else if (this.speed < 0 && this.autoReset) {
            this.speed += this.accelPerTick;
        }

        // Clamp the max speed
        if (this.speed > this.maxSpeed) {
            this.speed = this.maxSpeed;
        } else if (this.speed < -this.maxSpeed) {
            this.speed = -this.maxSpeed;
        }


        for (const cb of this.callbacks) {
            cb(this);
        }
    }

    /**
     * Emulate holding the decrement key
     */
    holdDecrementKey(inputType) {
        if (this.decrementingEnabled && this.enabledInputTypes.includes(inputType)) {
            this.hasDecKeyDown = true;
            this.incrementing = false;
        }
    }

    /**
    * Emulate holding the increment key
    */
    holdIncrementKey(inputType) {
        if (this.incrementingEnabled && this.enabledInputTypes.includes(inputType)) {
            this.hasIncKeyDown = true;
            this.incrementing = true;
        }
    }

    /**
     * Emulate releasing the decrement key
     */
    releaseDecrementKey(inputType) {
        if (this.enabledInputTypes.includes(inputType)) {
            this.hasDecKeyDown = false;

            if (this.holdIncrementKey) {
                this.incrementing = true;
            }
        }
    }

    /**
     * Emulate releasing the increment key
     */
    releaseIncrementKey(inputType) {
        if (this.enabledInputTypes.includes(inputType)) {
            this.hasIncKeyDown = false;

            if (this.holdDecrementKey) {
                this.incrementing = false;
            }
        }
    }
}