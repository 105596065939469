import * as THREE from 'three';
import config from '../config';
import { getState } from '../script';

var timeout;

/**
 * Listener record to keep track of an attached
 * event listener. Supporting proper unregistering on destroy
 */
export class ListenerRecord {
    target = null;
    type = null;
    func = null;

    constructor(target, type, func, options = false) {
        this.type = type;
        this.target = target;
        this.func= func;
        this.options = options;
        
        this.target.addEventListener(this.type, this.func, this.options);
    }

    destroy() {
        this.target.removeEventListener(this.type, this.func);
    }
}

export class Helpers {
    /**
     * Clamp value
     * @param {*} val the value 
     * @param {*} min the minimum
     * @param {*} max the maximum
     * @returns the clamped value in the range of [min, max]
     */
    static clamp(val, min, max) {
        return Math.min(Math.max(val, min), max);
    }

    /**
     * Clear all three instances for the object
     * @param {*} obj 
     */
    static clearThree(obj) {
        while (obj.children.length > 0) {
            Helpers.clearThree(obj.children[0]);
            obj.remove(obj.children[0]);
        }
        if (obj.geometry) obj.geometry.dispose();

        if (obj.material) {
            //in case of map, bumpMap, normalMap, envMap ...
            Object.keys(obj.material).forEach(prop => {
                if (!obj.material[prop])
                    return;
                if (obj.material[prop] !== null && typeof obj.material[prop].dispose === 'function')
                    obj.material[prop].dispose();
            })
            obj.material.dispose();
        }
    }


    /**
     * Load the 3d model gltf
     * @param {*} path path to the model
     * @returns the loaded gtlf object
     */
    static loadModel(path) {
        return new Promise((resolve, reject) => {
            getState().gltfLoader.load(`${config.basePath}/models${path}`,
                (gltf) => {
                    resolve(gltf);
                }
            );
        });
    }


    /**
     * Get the faded color at time 
     * @param {*} elapsedTime elapsed time offset
     * @param {*} options optionso object with totalTime and fadeColors
     * @returns the THREE.Color obbject with the color
     */
    static getFadedColor(elapsedTime, options = {}) {
        const totalTime = options.totalTime || config.totalTime;
        const fadeColors = options.fadeColors || config.fadeColors;

        const numberOfColors = fadeColors.length;
        const timePercentage = (elapsedTime % totalTime) / totalTime;
        const stepPercentage = (totalTime / (numberOfColors - 1.0)) / totalTime;

        let baseColor;

        for (let i = 0; i < numberOfColors - 1; i++) {
            if (timePercentage <= (i + 1.0) * stepPercentage) {

                const startColor = new THREE.Color(fadeColors[i]);
                const endColor = new THREE.Color(fadeColors[i + 1]);

                return baseColor = startColor.lerp(endColor, (timePercentage - (i * stepPercentage)) / stepPercentage);
            }
        }

        return baseColor;
    }


    /**
     * Calculate the intersection point between a ray and a plane
     * @param {*} rayVector the ray vector
     * @param {*} rayPoint the ray point
     * @param {*} planeNormal the normal of the plane
     * @param {*} planePoint the point on the plane
     * @returns THREE.Vector3 of the point
     */
    static calcRayPlaneIntersect(rayVector, rayPoint, planeNormal, planePoint) {

        // Calculate the intersection point of the ray and plane
        const diff = rayPoint.sub(planePoint);
        const prod1 = diff.dot(planeNormal);
        const prod2 = rayVector.dot(planeNormal);


        const prod3 = (prod1 / prod2) * 100;
        return rayPoint.sub(rayVector.multiplyScalar(prod3));
    }

    /**
    * Creates debounce function
    * @param func function to debounce 
    * @param wait time to wait before firing
    * @param immediate wether to fire on the rise
    * @returns debounced function that fires after "wait" period of nothing
    */
    static debounce(func, wait, immediate) {
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }
}