import GalaxyOverview from "../scenes/galaxy-overview";
import * as THREE from 'three';
import SimpleScene from "../scenes/simpleScene";
import { getState } from "../script";
import About from "../scenes/about";
import TerrainGen from "../scenes/terrain-gen";
import config from "../config";

export class SceneManager {
    _instance; // scene manager instance singleton
    _activeScene;
    idToScenesMap;
    slugToIdMap;

    constructor() {
        // Load all section styles automatically and push them into a map from id to section object
        const context = require.context('../scenes/', true, /^\.\/(?!index\.js).*\.js$/);

        this.idToScenesMap = {};
        this.slugToIdMap = {};

        // Generate map for id's and slugs
        context.keys().forEach((module) => {
            const scene = (context(module).default);
            try {
                const defaults = scene.getDefaults();
                const slug = defaults.slug || defaults.id;

                this.idToScenesMap[defaults.id] = scene;
                this.slugToIdMap[slug] = defaults.id;
            } catch (e) { }
        });
    }

    /**
     * Singleton pattern for scene manager
     * @returns instance of scene manager
     */
    static getInstance() {
        if (this._instance == null) {
            this._instance = new SceneManager();
        }
        return this._instance;
    }

    /**
     * Switch the scene to the one with the id
     * @param {*} id id of the scene
     */
    switchScene(id) {
        if (!this.idToScenesMap[id]) {
            alert("404 - todo make 404 scene");
            return;
        }

        // Remove the old scene
        try {
            getState().scene.remove(this._activeScene.scene);
            this._activeScene.destroy();
        } catch (e) { console.log(e)}

        // Create new scene
        this._activeScene = new this.idToScenesMap[id]();
        // add scene to the general container
        getState().scene.add(this._activeScene.scene);

    }

    /**
     * Get an instance of the active scne
     * @returns 
     */
    getActiveScene() {
        return this._activeScene;
    }

    /**
     * Tick update all active scenes
     * @param {*} elapsedTime the time elapsed since start
     */
    tick(elapsedTime) {
        if (!this._activeScene) {
            return;
        }

        // Call the update of the scene on every tick
        try {
            if (typeof this._activeScene.onTick == "function") {
                this._activeScene.tick(elapsedTime);
            }
        } catch (e) {
            console.error(`SB Scene Manager: error occured onTick with scene ${this._activeScene.id}`, e)
        }

    }


    setContent() {
        //TODO: insert at correct place
    }

}