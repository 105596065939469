
import SBScene from './scene.js';
import * as THREE from 'three';
import { Helpers } from '../handlers/helpers.js';
import { getState } from '../script.js';
import config from '../config.js';
import {gsap} from 'gsap';
export default class SpaceStation extends SBScene {

    constructor() {
        super();
    }

    static getDefaults() {
        return {
            id: "space-station",

            spaceship: null,
            spacestation: null
        }

    }

    template() {
        return `
            <h1 style="z-index: 9999; font-size: 90px !important;color: #333333;position: fixed; left: 90px; top: 30vh">SPACEBLEND</h1>
            <h3 class="sb-color-fade" style="  z-index: 9999; font-size: 60px !important; color: #333333;position: fixed; left: 90px; top: calc(30vh - 60px)">Lorem Ipsum</h1>
        `
    }

    onCreate() {
        const state = getState();
        const camera = state.camera;
        
        state.controls.enabled = false;

        camera.position.set(-1.5254, 0.8388, -5.7448);
        camera.rotation.set(2.95,-0.52, 3.06);

        state.scene.background = new THREE.Color("white");




        // Load the spaceship
        Helpers.loadModel("/spaceship.gltf").then((gltf) => {
            this.spaceship = gltf.scene.children[0]; // get the spaceship model

            // Create / set the ship
            this.spaceship.material = new THREE.MeshStandardMaterial({
                roughness: 0.8,
                color: new THREE.Color(0x333333)
            });

            // Position ship
            this.spaceship.scale.set(0.15, 0.15, 0.15);
            this.spacestation.position.set(0, -0.5, 0);
            this.spaceship.rotation.set(0.5 * Math.PI, 0 * Math.PI, 0.33 * Math.PI);

            this.scene.add(this.spaceship);

            const tl = gsap.timeline({repeat: Infinity});
            this.spaceship.position.y = 0.2;
            tl.to(this.spaceship.rotation,  {z: 2* Math.PI, ease: gsap.Linear, duration: 6});

        });

        // Load the spaceship
        Helpers.loadModel("/spacestation.gltf").then((gltf) => {
            this.spacestation = new THREE.Group();
            this.spacestation.material = new THREE.MeshStandardMaterial({
                roughness: 0.8,
                color: new THREE.Color(0x333333)
            });

            for (const child of gltf.scene.children) {
                this.spacestation.add(child);
                child.material = this.spacestation.material;
            }


            // Position ship
            this.spacestation.scale.set(0.15, 0.15, 0.15);
            this.spacestation.rotation.set(0, 0.5 * Math.PI, 0 * Math.PI);

            this.scene.add(this.spacestation);
        });

        const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight.position.z = 15;
        directionalLight.position.y = 15;
        this.scene.add(directionalLight);

        const light = new THREE.AmbientLight(0x404040); // soft white light
        this.scene.add(light);

    }

    onTick(offset) {
        if (this.spaceship && this.spacestation) {
            this.spaceship.material.color = Helpers.getFadedColor(offset);
            this.spacestation.material.color =Helpers.getFadedColor(offset);    
        }
        const camera = getState().camera;
    }
}




