
import SBScene from './scene.js';

export default class Scene404 extends SBScene {

    constructor() {
        super();
    }

    static getDefaults() {
        return {
            id: "404",
        }

    }

    template() {
        return `
        <h1 style="position: relative; top: 0px;
        left: 50%;margin-left: auto; margin-right: auto;color: white; font-size: 90px">404</h1>`
    }

    onCreate() {
    }
}




