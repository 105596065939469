import { SceneManager } from "./sceneManager";
import config from '../config';
import { MenuHandler } from "./menuHandler";

export class HistoryHandler {
    _instance;

    init() {

        this.onHashChange();

        // listen to changes of hash
        window.addEventListener("hashchange", this.onHashChange)
    }


    /**
     * Handle the hash change event
     * to navigate to the correct scene
     */
    onHashChange() {
        const sceneManager = SceneManager.getInstance();
        const sceneSlug = window.location.hash.substr(1);

        let id;
        // get the id of the new scene to navigate to
        if (window.location.hash.length <= 1) {
            id = config.defaultSceneID;
        } else {
            id = sceneManager.slugToIdMap[sceneSlug] || config.scene404ID;
        }

        // close the menu 
        MenuHandler.getInstance().closeMenu();
        // navigate to the new scene
        sceneManager.switchScene(id);
    }

    /**
     * Singleton pattern for History Handler
     * @returns instance of the history handler
     */
    static getInstance() {
        if (this._instance == null) {
            this._instance = new HistoryHandler();
        }
        return this._instance;
    }

}
